import { Breadcrumb, Card, Col, Divider, Row, Modal, message, Button } from 'antd';
import { HomeOutlined, UserSwitchOutlined } from '@ant-design/icons';

// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
// API
import APIService from '@/services/API';
import PagesTable from '@/components/tables/Pages.table';
import { RawPage } from '@/interfaces/page.interface';
import { useNavigate } from 'react-router-dom';

const PagesScreen = () => {
  const nav = useNavigate();
  const { t } = useTranslation();
  const [params, setParams] = useState({
    $skip: '0',
    $limit: '10',
    '$sort[created_at]': '-1',
  });

  const { isLoading, data, refetch } = useQuery(['pages', params], () =>
    APIService.getPages(params),
  );

  const handleDelete = async (id: string) => {
    const response = await APIService.deletePage(id);
    if (response.ok) {
      refetch();
      message.success(t('roles.deleteSuccess'));
    } else {
      message.error(response.data?.message);
    }
  };

  const onDelete = (id: string) => {
    Modal.confirm({
      title: t('roles.deleteConfirmation'),
      okText: t('general.yes'),
      onOk: () => handleDelete(id),
    });
  };

  const onEdit = (data: RawPage) => {
    nav('/dashboard/pages/' + data._id);
  };

  return (
    <div className="dashboard-screen fadeIn">
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Breadcrumb
              items={[
                {
                  href: '',
                  title: <HomeOutlined />,
                },
                {
                  href: '',
                  title: (
                    <>
                      <UserSwitchOutlined />
                      <span>{t('menu.pages')}</span>
                    </>
                  ),
                },
              ]}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={() => nav('/dashboard/pages/new')}>
              {t('general.create')}
            </Button>
          </Col>
        </Row>
        <Divider />
        <PagesTable
          onFilter={setParams}
          data={data?.data}
          loading={isLoading}
          total={data?.total}
          current={(data?.skip || 0) / (data?.limit || 1)}
          {...{ onDelete, onEdit }}
        />
      </Card>
    </div>
  );
};

export default PagesScreen;
